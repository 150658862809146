export const UFSessionStateEnum: Record<string, string> = {
  created: "Créée",
  running_single: "En cours (individuelle)",
  running_group: "En cours (groupe)",
  finished: "Terminée",
  pending: "En attente",
};

export const UFSessionStateColorEnum: Record<string, string> = {
  created: "primary",
  running_single: "info",
  running_group: "info",
  finished: "gray",
  pending: "warning",
};

export const UFliterals: Record<string, string> = {
  backToPreviousFeedback: "Retour au feedback précédent",
  mine: "Mes",
  other: "Autres",
  constraints: "Contraintes",
  payoff: "Rétribution",
  student: "Étudiant",
  register: "S'inscrire",
  generateRandomCode: "Générer le code",
  groupComposition: "Composition des groupes",
  regenerateRandomCode: "Regénérer le code",
  addActivity: "Ajouter une activité à la séquence",
  addOtherActivity: "Ajouter une autre activité à la séquence",
  groupCreation: "Constitution des groupes",
  generateGroups: "Regénérer les groupes",
  randomGenerateGroups: "Générer les groupes aléatoirement",
  enterSequenceCode:
    "Entrez le code de la séquence que vous souhaitez rejoindre.",
  askToJoinSequence: "Demander à rejoindre la séquence",
  noSequenceFound: "Aucune séquence correspondante. Veuillez vérifier le code.",
  notDispatchedInSequence:
    "Vous n'êtes pas associé à cette séquence. Contactez votre formateur.",
  twoLastPhoneNumbers: "Les 2 derniers chiffres de votre numéro de téléphone",
  otp: "Code reçu par email",
  emailSent: "L'e-mail a bien été envoyé veuillez vérifier votre boite mail.",
  resendEmail: "Renvoyer un e-mail",
  getCode: "Obtenir le code",
  robotCheckExplain:
    "Pour vérifier que vous n'êtes pas un robot nous avons besoin de vous\n" +
    "  faire parvenir un code par email.",
  availableSequences: "Séquences disponibles",
  sequences: "Séquences",
  birthDay: "Jour de naissance",
  gender: "Genre",
  home: "Accueil",
  activity_name: "Nom de l'activité",
  sequenceCode: "Code de la séquence",
  id: "Identifiant",
  title: "Intitulé",
  type: "Type",
  player_number: "Effectif du groupe",
  tips: "Conseils",
  single_session_time: "Durée session individuelle (minutes)",
  group_session_time: "Durée session groupe (minutes)",
  moved_at: "Bougé à",
  old_index: "Ancien indice",
  start_activity: "Démarrer l'activité",
  display_next_tip: "Afficher le conseil suivant",
  tip_overview_intro:
    "Pour vous aider dans ce travail en équipe, lisez attentivement les conseils suivants.",
  see_results: "Voir les résultats",
  new_index: "Nouvel indice",
  user_id: "Identifiant d'utilisateur",
  session_id: "Identifiant de session",
  item_id: "Identifiant d'item",
  dragged_from: "Déplacé depuis",
  dragged_to: "Déplacé vers",
  username: "Nom d'utilisateur",
  last_name: "Nom",
  first_name: "Prénom",
  avatar: "Avatar",
  author: "Auteur",
  code: "Code",
  settings: "Activités paramétrées",
  email: "E-mail",
  started_time: "Commencé à",
  ended_time: "Terminé à",
  status: "Statut",
  estimated_individual_end_time: "Heure de fin de session individuelle estimée",
  estimated_group_end_time: "Heure de fin de session de groupe estimée",
  activity_id: "Identifiant d'activité",
  chat_room_id: "Identifiant de chat",
  chat_room: "Chat",
  individual_session_results: "Résultats de sessions individuelles",
  messages: "Messages",
  pseudo: "Nom d'utilisateur",
  created_at: "Date de création",
  closed_at: "Date de cloture",
  posted_at: "Date de publication",
  display_at: "Afficher à (% de temps)",
  remove_at: "Retirer à (% de temps)",
  content: "Contenu",
  author_id: "Auteur",
  label: "Libellé",
  description: "Description",
  is_active: "Actif",
  attendance_type: "Type de participation",
  tips_display_mode: "Modalité d'affichage des conseils",
  expected_rank: "Position attendue",
  submitted_at: "Date de soumission",
  scope: "Portée",
  color: "Couleur",
  sequence_id: "Identifiant de séquence",
  guideline: "Consigne",
  about: "A propos",
  items: "Eléments",
  hybrid: "Hybride",
  remote: "Distanciel",
  physical: "Présentiel",
  text_only: "Communication par texte uniquement",
  feedback: "Afficher le feedback",
  feedbacks: "Participation",
  display_feedback_message: "Afficher le message de feedback",
  display_indiv_scores: "Afficher score individuel sur le feedback",
  display_group_score: "Afficher score de groupe sur le feedback",
  ranking: "Classement",
  individual: "Individuel",
  group: "Groupe",
  highlight_begin_at: "Début de saillance (% de temps)",
  highlight_end_at: "Fin de saillance (% de temps)",
  highlight: "Saillance",
  tip_groups: "Conseils",
  create: "Créer",
  edit: "Modifier",
  delete: "Supprimer",
  already_taken: "Cette valeur est déjà prise.",
  clear: "Effacer",
  consult: "Consulter le feedback",
  access: "Accéder à la séquence",
  clone: "Dupliquer",
  getMySequences: "Obtenir mes séquences",
  activities: "Activités",
  activity: "Activité",
  sequence: "Séquence",
  pending: "Vous êtes en attente.",
  noData: "Aucune donnée disponible.",
  dispatched:
    "Vous avez été intègre à un groupe, nous vous redirigeons vers la séquence.",
  approved:
    "Vous avez été accepté dans la session. Nous attendons maintenant que votre formateur fasse les groupes.",
  denied: "Vous avez été refusé de la session.",
  debriefing: "Debriefing",
  trackingTool: "Outil de suivi",
  notImplementedYet:
    "Désolé, cette fonctionnalité n'est pas encore disponible.",
  noResult: "Aucun résultat trouvé",
  loadingDataText: "Chargement des données...",
  search: "Rechercher",
  password: "Mot de passe",
  leave: "Quitter",
  creation: "Création",
  edition: "Modification",
  cancel: "Annuler",
  launch: "Lancer une activité",
  del: "Supprimer",
  undetermined: "Non déterminé",
  add: "Ajouter",
  start: "Commencer",
  next: "Suivant",
  close: "Fermer",
  group_tips_id: "Conseils de groupe",
  indiv_tips_id: "Conseils individuels",
  is_open: "Ouverte",
  remainingTime: "Temps restant avant la fin de l'activité",
  authorized_instructors: "Formateurs autorisés à accéder a cette séquence",
  group_based_feedback: "Feedback de synergie",
  selected_items: "Object selectionnés",
  available_items: "Elements à classer",
  delete_slide: "Supprimer la page",
  reload_list: "Recharger la liste",
  you_can_ask_your_user_to_follow_this_link:
    "Vous pouvez désormais demander à vos participants de rejoindre le lien\n" +
    "    suivant",
  sequence_code_is: "Le code de la séquence est",
  who_has_joined: "Participants ayant rejoint la session",
  no_one_joined: "Personne n'est connecté pour le moment.",
  joined_number: "Nombre de participants",
  confirm: "Confirmer",
  scores: "Performance",
  backgroundImg: "Image de fond",
  sequence_details: "Details de la sequence",
  create_groups: "Constituer les groupes",
  dynamic_feedback: "Feedback interactif",
  room: "Chambre",
  logIn: "Connexion",
  logOut: "Déconnexion",
  creationTools: "Outils de création",
  trainerHelp: "support pour les sessions",
  createdSequences: "Séquences créées",
  runningSequences: "Séquences en cours",
  finishedSequences: "Séquences terminées",
  finishedSequence: "Séquence terminée",
  taskTransitionTitle: "La session individuelle est terminée",
  taskTransitionText:
    "Vous allez maintenant réaliser l’activité en équipe afin de vous" +
    " mettre d’accord sur le classement de différents éléments.",
  taskTransitionButton: "Accéder à la session de groupe",
  availableItems: "Elements à classer",
  myRanking: "Mon classement",
  // tip_overview: "Afficher la page de conseil",
  tip_overview: "Afficher les conseils avant la séance de groupe",
  tips_overview: "Conseils avant la séance de groupe",
  confirmRanking: "Valider le classement",
  confirmGroup: "Valider les groupes",
  intro: "Page introductive de l’activité",
  page: "Page",
  editCancelText: "Êtes-vous sûr de vouloir annuler la modification ? ",
  createCancelText: "Êtes-vous sûr de vouloir annuler la création ? ",
  deleteConfirmText: "Êtes-vous sûr de vouloir supprimer cet élément ?",
  yesCancelCreate: "Confirmer l'annulation",
  back: "Retour",
  yesCancelEdit: "Oui, annuler la modification",
  itemsPerPageText: "Résultats par page",
  all: "Tous",
  video: "Vidéo",
  tipChoice: "Choix des conseils",
  completion_display_at: "Afficher à (x éléments classés)",
  completion_remove_at: "Retirer à (x éléments classés)",
  completion_highlight_at: "Début de saillance (x éléments classés)",
  completion_highlight_end_at: "Fin de saillance (x éléments classés)",
  export_data: "Export de données",
  first_feedback: "Ajouter le premier feedback",
  second_feedback: "Ajouter le second feedback",
  read_more: "En savoir plus",
  display_score: "Afficher le détail des participants",
  dashboard: "Tableau de bord",
  results: "Résultats",
  opened_at: "Date de réalisation",
  display_on_chart: "Afficher sur les graphiques",
  seeGroupComposition: "Visualiser la composition des groupes",
  participation: "Participation",
  performance: "Performance",
  participationOverview: "Apercu de participation",
  performanceOverview: "Apercu de performance",
  discipline: "Discipline",
  economie_gestion : "Économie-gestion",
  sti_genie_mecanique : "STI-Génie Mécanique",
  genie_aeronautique : "Génie Aéronautique",
  maths_physique_chimie : "Maths-Physique-Chimie",
  biotechnologies_sante_environnement : "Biotechnologies Santé Environnement",
  lettres_histoire_geographie : "Lettres-Histoire/Géographie",
  metiers_de_la_mode : "Métiers de la Mode",
  sciences_et_techniques_medico_sociales : "Sciences et Techniques Médico-sociales",
  genie_industriel_bois : "Génie industriel bois",
  genie_civil : "Génie civil",
  hotellerie_restauration : "Hôtellerie restauration",
  anglais_lettres : "Anglais lettres",
  melec : "MELEC",
  autre : "Autre"
};

export const UFDialogText: Record<string, string> = {
  finishedSession: "La session est terminée.",
  joiningSequence:
    "Votre formateur a été informé de votre demande. " +
    "Nous attendons à présent que les autres participants se connectent avant de commencer. ",
};

export const UFButtonText: Record<string, string> = {
  backToHomePage: "Revenir à l'accueil",
  goToHome: "Accueil",
  goToFeedback: "Voir le feedback",
  nextFeedback: "Voir le feedback suivant",
};

export const browserSniffingMessage = (
  currentBrowser: string,
  targetBrowsers: string[] = ["Firefox"]
): string => {
  return `<p>Désolé, cette application ne peut pas fonctionner sur ${currentBrowser}.</p>
    <p>Veuillez essayer avec firefox. <br>
    <a href="https://www.mozilla.org/fr/firefox/new/" target="_blank">Voici un lien sur l'installation de firefox.</a></p>`;
};
